<template>
  <div class="bg-cream">
    <Nav color="blue"></Nav>
    <section id="shop" class="padding-main">
      <div class="container-large">
        <h1 class="blue">Shop Red + Blue Stories</h1>
        <div class="row mt-2 mb-4">
          <div class="mb-2 row gap-2">
            <div class="filter-block blue-block" @click="setFilter(`interactive-story`)" :class="filterSelected === 'interactive-story' ? 'active' : ''">
              <img src="../../assets/img/filters_is.png">
              <h5>Interactive<br>Stories</h5>
            </div>
            <div class="filter-block teal-block" @click="setFilter(`hidden-postcards`)" :class="filterSelected === 'hidden-postcards' ? 'active' : ''">
              <img src="../../assets/img/filters_is.png">
              <h5>Hidden: <br>AR Postcards</h5>
            </div>
<!--            <label for="filter" class="blue">filter by:</label>-->
<!--            <select id="filter" name="filter" v-model="filterSelected">-->
<!--              <option value="none">None</option>-->
<!--              <option value="hidden-postcards">Hidden: AR Postcards</option>-->
<!--              <option value="interactive-story">Interactive Stories</option>-->
<!--            </select>-->
          </div>
          <p class="align-right fw blue">{{nbProducts}} PRODUCTS</p>
          <div class="product-grid grid-4">
            <ProductThumbnail v-for="product in products" v-bind:key="product.itemId" :product="product" v-show="containsTag(product.tags)"></ProductThumbnail>
          </div>
        </div>
      </div>
    </section>
    <Footer color="cream"></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import ProductThumbnail from "@/components/ProductThumbnail.vue";
import Nav from "@/components/Nav.vue";

export default {
  name: 'Shop',
  props: ['filter'],
  components: {
    ProductThumbnail,
    Footer,
    Nav
  },
  data () {
    return {
      filterSelected: 'none'
    }
  },
  computed: {
    products() {
      return this.$store.getters.getAllProducts;
    },
    nbProducts() {
      if (this.filterSelected === 'none') {
         return this.products.length;
      } else {
        var nbProducts = 0;
        for (var i = 0; i < this.products.length; i++) {
          if (this.containsTag(this.products[i].tags))
            nbProducts++;
        }
        return nbProducts;
      }
    }
  },
  methods: {
    containsTag(tags) {
      if (this.filterSelected === 'none') return true;
      return tags.includes(this.filterSelected);
    },
    setFilter(filter) {
      if (this.filterSelected !== filter) {
        this.filterSelected = filter;
      } else {
        this.filterSelected = 'none';
      }

    }
  },
  created() {
    if (this.filter != null) {
      this.filterSelected = this.filter;
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

#sorting-options {
  margin-bottom: 2em;
}

label {
  font-family: Poppins, sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  margin-right: 1em;
}

select {
  font-family: Poppins, sans-serif;
  background-color: transparent;
  font-size: 1em;
  border: none;

  &:focus {
    border: none;
  }
}

.product-grid {
  border-top: 1px solid $blue;
  padding-top: 2em;
}

.filter-block {
  display: inline-flex;
  align-items: center;
  gap: 1em;
  padding: .4em 3em .4em .4em;
  border-radius: 11px;
  line-height: 150%;
  cursor: pointer;
  border: 1px solid transparent;

  img {
    max-width: 80px;
    border-radius: 10px;
  }

  h5 {
    font-size: 1.2em;
    font-weight: 700;
  }

  &.blue-block {
    background-color: $blue;
    color: $teal;

    &:hover {
      background-color: $blue-hover;
    }

    &.active {
      border: 1px solid $teal;
    }
  }

  &.teal-block {
    background-color: $teal;
    color: $blue;

    &:hover {
      background-color: $teal-hover;
    }

    &.active {
      border: 1px solid $blue;
    }
  }
}
</style>